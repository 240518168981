import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/* global baseUrl,baseConfiguratorUrl, giftcardAmountButtons, cards, configuratorImages, productName, shippingInformationIcon */
const defaultState = () => {
    if (typeof(injectState) !== 'undefined') {
        return injectState();
    }
    return {}
}

const state = Object.assign({
    giftcardData: [],
    messagePrice: 0,
    packings: [],
    stepsComplete: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false
    },
    groupsComplete: [],
    currentStep: 1,
    giftcardAmount: 0,
    qty: 1,
    storeCode: window.checkoutConfig ? window.checkoutConfig.storeCode : '',
    selectedCard: {
        sku: 0,
        option_id: 0,
        price: 0,
        name: '',
        image: ''
    },
    selectedPacking: {
        selection_id: 0,
        option_id: 0,
        price: 0,
        name: '',
        image: ''
    },
    selectedOptions: [],
    optionCardId: 0,
    message: '',
    recipients: [],
    actionDisabled: true,
    disableNextStep: false,
    addToCartFromMultiship: false,
    multiShip: false,
    multiShipAdresses: [],
}, defaultState());


const store = new Vuex.Store({
    state,
    actions: {
        resetMessage({commit}) {
            commit('message', '');
        }
    },
    mutations: {
        addRecipient (state, payload) {
            const recipient = {
                email: payload
            }
            state.recipients.unshift(recipient)
            state.qty = state.recipients.length
        },
        saveRecipient (state, { index, email }) {
            state.recipients[index].email = email
        },
        removeRecipient (state, index) {
            state.recipients.splice(index, 1)
            state.qty = state.recipients.length
        },
        resetRecipients (state) {
            state.recipients = []
            state.qty = 0
        },
        locationCount (state, payload) {
            state.locationCount = payload
        },
        giftcardAmount (state, payload) {
            payload = payload.toString().replace(/,/g, '.')
            state.giftcardAmount = payload
        },
        qty (state, payload) {
            state.qty = payload
        },
        stepsComplete (state, payload) {
            state.stepsComplete = {...payload}
        },
        groupsComplete (state, payload) {
            state.groupsComplete = payload
        },
        currentStep (state, payload) {
            state.currentStep = payload
        },
        selectedCard (state, payload) {
            state.selectedCard = {...payload}
        },
        selectedPacking (state, payload) {
            state.selectedPacking = payload
        },
        selectedOptions (state, payload) {
            state.selectedOptions = {...payload}
        },
        optionCardId (state, payload) {
            state.optionCardId = payload
        },
        message (state, payload) {
            state.message = payload
        },
        recipients (state, payload) {
            state.recipients = payload
        },
        messagePrice (state, payload) {
            state.messagePrice = payload
        },
        actionDisabled (state, payload) {
            state.actionDisabled = payload
        },
        disableNextStep (state, payload) {
            state.disableNextStep = payload
        },
        addToCartFromMultiship (state, payload) {
            state.addToCartFromMultiship = payload
        },
        changeMultiship (state, payload) {
            state.multiShip = payload
        },
        saveAddresses (state, payload) {
            state.multiShipAdresses = payload
        }

    },
    getters: {
        getProductName: state => {
            return state.productName
        },
        giftcardAmountButtons (state) {
            return state.giftcardAmountButtons
        },
        baseUrl (state) {
            return state.baseUrl
        },
        baseConfiguratorUrl (state) {
            return state.baseConfiguratorUrl
        },
        switcherBaseUrl (state) {
            return state.switcherBaseUrl
        },
        switcherUrlLabel (state) {
            return state.switcherUrlLabel
        },
        giftcardAmount: state => {
            return parseFloat(state.giftcardAmount)
        },
        giftcardData: state => {
            return state.giftcardData
        },
        configuratorImages: state => {
            return state.configuratorImages
        },
        messagePrice: state => {
            return state.messagePrice
        },
        cards: state => {
            return state.cards
        },
        packings: state => {
            return state.packings
        },
        qty: state => {
            return state.qty
        },
        stepsComplete: state => {
            return state.stepsComplete
        },
        groupsComplete: state => {
            return state.groupsComplete
        },
        currentStep: state => {
            return state.currentStep
        },
        selectedCard: state => {
            return state.selectedCard
        },
        selectedPacking: state => {
            return state.selectedPacking
        },
        selectedOptions: state => {
            return state.selectedOptions
        },
        optionCardId: state => {
            return state.optionCardId
        },
        message: state => {
            return state.message
        },
        recipients: state => {
            return state.recipients
        },
        actionDisabled: state => {
            return state.actionDisabled
        },
        getDisableNextStep (state) {
            return state.disableNextStep
        },
        getAddToCartFromMultiship (state) {
            return state.addToCartFromMultiship
        },
        multiShip (state) {
            return state.multiShip
        },
        multiShipAdresses(state) {
            return state.multiShipAdresses
        },
        getConfigurationUrl(state) {
            let selectionOptions = state.selectedOptions;
            delete selectionOptions['mutationFix'];

            let configurationString = [];
            let groupMappings = {};
            let groupMappingsUrl = {};
            let groups = state.selectedCard.option_groups;
            let groupsComplete = state.groupsComplete;

            let keys = Object.keys(selectionOptions);
            let values = Object.values(selectionOptions);

            for (let key in groups) {
                let group = groups[key];
                if (group.has_categories || !group.required || Object.keys(group.options).length > 1) {
                    if (group.group_url_key) {
                        groupMappingsUrl[group.group_url_key] = group.id;
                        groupMappings[group.id] = group.group_url_key;
                    } else {
                        groupMappingsUrl[group.title] = group.id;
                        groupMappings[group.id] = group.title;
                    }
                }
            }

            configurationString.push('waarde');
            configurationString.push(state.giftcardAmount.toString().replace('.', ','));

            if(Object.keys(cards).length > 1 &&  groupsComplete.includes('kaart')){
                configurationString.push('kaart');
                configurationString.push(state.selectedCard.product_url_key);
            }
            for (const [urlKey, id] of Object.entries(groupMappingsUrl)) {
                if(groupsComplete.includes(urlKey)){
                    let value = 'geen-' + urlKey;

                    if(selectionOptions[id]){

                        value = selectionOptions[id].product_url_key
                    } else if (urlKey === 'bericht') {
                        if(state.message){
                            value = false;
                        }
                    }

                    if(value){
                        configurationString.push(urlKey);
                        configurationString.push(value);
                    }
                }
            }

         /*   urlKeys.forEach((value, index) => {
                if (values[index]) {
                    if(groupMappings.hasOwnProperty(value)){
                        if(groupsComplete.includes(groupMappings[value])){
                            configurationString.push(groupMappings[value]);
                            configurationString.push(values[index].product_url_key);
                        }
                    }
                }

            });*/

            if (configurationString.join('/').trim()) {
                return `${configurationString.filter(Boolean).join('/').trim()}`;
            }

            return '';
        },
        getPreConfigurationData() {
            let preConfiguration = {};
            let configUrl = window.location.href;
            configUrl = configUrl.replace(baseUrl, '').replace(baseConfiguratorUrl, '').substring(1);
            if (configUrl.length > 1) {
                let pathMatch = configUrl.split('/');
                let filterKey;

                pathMatch.forEach((value, index) => {
                    if (!(index % 2)) {
                        filterKey = value;
                    }else{
                        value = value.replace(/\?[\w]+.*/g, '');
                        preConfiguration[filterKey] = value
                    }
                });
            }

            return preConfiguration;
        },
        getMaximumCsvAddresses (state) {
            return parseInt(state.maximumCsvAddresses)
        },
        shippingInformationIcon: state => {
            return state.shippingInformationIcon
        }
    }
})

export default store
