export default {
    data() {
        return {
            email: null
        }
    },
    computed: {
        isValid: function () {
            return this.validEmail(this.email)
        },
    },
    methods: {
        validEmail: function (email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validateRecipients() {
            const hasInvalidEmail = this.recipients
                .map((item) => this.validEmail(item.email))
                .includes(false);
            const isEditing = this.recipients
                .map((item, index) => this.$refs[`recipient-${index}`] !== undefined && this.$refs[`recipient-${index}`][0] !== undefined && this.$refs[`recipient-${index}`][0].editing)
                .includes(true);

            if (this.recipients.length < 1) {
                this.errors.push('Vul ten minste een e-mailadres van een ontvanger in.');
            } else if (hasInvalidEmail) {
                this.errors.push('Vul alleen valide e-mailadressen in. ');
            } else if (isEditing) {
                this.errors.push('U bent nog aan het bewerken.')
            }

            return (!this.errors.length);
        },
    }
}
