<template>
     <b-modal id="modal-edit-message" dialog-class="modal-dialog step-recipients" no-close-on-backdrop>
        <template v-slot:modal-header>
            <h1 class="page-title">Bekijk en bewerk bericht</h1>
            <div>
                <button class="button-round" @click.prevent="hideModal"><i class="times"></i></button>
            </div>
        </template>
        <template v-slot:default>
            <div class="style-inline-helper" >
                <textarea v-model="message" name="" id="newMessage" rows="5" :maxlength="maxMessageLength" style="resize: none;" spellcheck="false" placeholder="Message"></textarea>
                <div class="form-helper">
                    je hebt nog {{charsRemaining}} karakters over
                </div>
            </div>
        </template>
        <template v-slot:modal-footer>
            <div>
                <button
                    class="button style-next size-large"
                    @click.prevent="updateCart()"
                >
                    Bericht opslaan
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import bModal from 'bootstrap-vue/es/components/modal/modal';
import JQuery from 'jquery'

let $ = JQuery

export default {
    components: {
        'b-modal': bModal,
    },
    props: {
        itemId: {
            type: Number | null,
            required: false,
            default: null
        }
    },
    data () {
         return {
            maxMessageLength: 200
        }
    },
    computed: {
        message: {
            get: function () {
                return this.$store.state.message;
            },
            set: function (value) {
                this.$store.commit('message', value)
            }
        },
        charsRemaining() {
            return this.maxMessageLength - this.message.length;
        },
    },
    methods: {
            hideModal() {
                this.$root.$emit('bv::hide::modal', 'modal-edit-message')
            },
            updateCart() {
                let item_id = document.querySelector('.action-edit-message').dataset.cartItem;
                let url = ''
                if (this.$store.state.storeCode === 'zakelijk') {
                    url = '/zakelijk/checkout/messagerecipients/post'
                } else {
                    url = '/checkout/messagerecipients/post'
                }

                $.ajax({
                    showLoader: true,
                    url: url,
                    type: 'POST',
                    data: {
                        item_id: this.itemId,
                        recipients: this.$store.state.recipients,
                        message: this.message,
                    },
                    dataType: 'json'
                }).done(function(data) {
                    location.reload();
                })
            },
    }
}
</script>
