<template>
  <div @click="$emit('clicked', true)" class="email-errors" v-html="errorsToShow"></div>
</template>

<script>
export default {
  props: {
    errorsToShow: {
      default: ''
    }
  }
}
</script>