<template>
    <div class="recipient-item" @keyup.esc.prevent="discardChanges">
        <span class="recipient" :class="{ edit: editing, 'has-error': !isValid }">
            <span class="order-number">{{ index + 1 }}</span>
            <input type="email"
                   :ref="`edit-${_uid}`"
                   name="edit_email[index]"
                   v-model="email"
                   :readonly="! editing"
                   @keyup.enter.prevent="submitRecipient"
            />
        </span>
        <span v-if="!readOnly" class="actions">
            <i v-if="editing && isValid" class="fa fa-check" @click.prevent="submitRecipient"></i>
            <i v-else-if="editing" class="fa fa-times" @click.prevent="discardChanges"></i>
            <i v-else class="fa fa-pencil" @click.prevent="editRecipient"></i>
            <i class="fa fa-trash" @click.prevent="removeRecipient"></i>
        </span>
    </div>
</template>

<script>
import Validation from "../../../mixins/email-recipient";

export default {
    props: {
        index: {
            type: Number,
            required: true
        },
        recipient: {
            type: Object,
            required: true
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mixins: [Validation],
    data() {
        return {
            email: this.recipient.email,
            editing: false,
        }
    },
    methods: {
        editRecipient() {
            this.editing = true;
            this.$refs[`edit-${this._uid}`].focus();
            this.$emit('edit', this);
        },
        submitRecipient() {
            if (!this.isValid) {
                return;
            }

            this.recipient.email = this.email;
            this.editing = false;
            this.$emit('save', this);
        },
        discardChanges() {
            this.email = this.recipient.email;
            this.editing = false;
            this.$emit('edit', this);
        },
        removeRecipient() {
            this.$emit('remove', this.index);
        }
    }
}
</script>
