export default {
    data() {
        return {
            errors: [],
            componentKey: 0,
        }
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        },
    }
}
