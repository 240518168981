import JQuery from 'jquery'
let $ = JQuery

const call = (addresses, qty) => {
  return $.ajax({
    showLoader: true,
    url: '/multishipping/address/validation',
    type: 'POST',
    data: {
        addresses: addresses,
        qty: qty
    },
    dataType: 'json'
  })
}

export default call
