<template>
  <b-modal
    id="modal-multiship"
    dialog-class="modal-dialog step-multiship"
    size="lg"
    :hide-footer="!validationBad"
    no-close-on-backdrop
    @hide="makeItSingleAddress"
  >
    <template v-slot:modal-header>
      <h1 class="page-title">{{ validationBad ? 'Fout(en) gevonden' : 'Adressen uploaden' }}</h1>
      <div class="close-modal-wrapper">
        <span class="close-modal-helper">Sluiten</span>
        <button class="button-round" @click.prevent="hideModal">
          <i class="times"></i>
        </button>
      </div>
    </template>
    <template v-slot:default>
      <hr class="divider" style="height: 0px; border: none; border-top: 1px solid #F4F6F8; margin: 0 auto; width: 953px;">
      <div style="height: 600px; display: flex;">
        <div class="multiship-wrapper" v-if="loading || validationGood || !validationBad">
          <div class="multiship-upload" v-show="!loading && !validationGood && !validationBad">
            <div class="multiship-details">
              <img :src="require('../../../../../img/upload.png')" alt="upload">
              <h3 class="title">Upload bestand met {{ qty }} adressen</h3>
            </div>
            <div class="multiship-upload-wrapper">
                <div class="multiship-upload-details">
                    <p class="multiship-upload-details-title">Volg de volgende stappen:</p>
                    <div class="multiship-upload-details-item">
                        <span class="multiship-upload-details-item-number">1</span>
                        <p class="multiship-upload-details-item-body">
                            <a :href="downloadExampleUrl" target="_blank">
                                Download hier een voorbeeldbestand
                            </a>
                        </p>
                    </div>

                    <div class="multiship-upload-details-item">
                        <span class="multiship-upload-details-item-number">2</span>
                        <p class="multiship-upload-details-item-body">
                            Vul deze in met de juiste gegevens
                        </p>
                    </div>

                    <div class="multiship-upload-details-item">
                        <span class="multiship-upload-details-item-number">3</span>
                        <p class="multiship-upload-details-item-body">
                            Verwijder leestekens (zoals ?,!, ;) <br/> en speciale karakters (zoals ä, è, í, ô, ñ)
                        </p>
                    </div>

                    <div class="multiship-upload-details-item">
                        <span class="multiship-upload-details-item-number">4</span>
                        <p class="multiship-upload-details-item-body">
                            Sla het bestand op als .CSV UTF8
                        </p>
                    </div>

                    <div class="multiship-upload-details-item">
                        <span class="multiship-upload-details-item-number">5</span>
                        <p class="multiship-upload-details-item-body">
                            Upload het bestand hiernaast
                        </p>
                    </div>
                </div>
                <div class="multiship-upload-button">
                    <img :src="require('../../../../../img/upload-two.png')" alt="upload-two">
                    <button class="pagebuilder-button-primary button color-cta-white" @click.prevent="$refs['input-file'].click()">Upload .csv bestand</button>
                </div>
            </div>
          </div>

          <div v-show="loading && !validationGood && !validationBad" class="text-center">
            <div class="loader-wrapper">
              <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              <p class="loader-text">Wordt geladen</p>
            </div>
          </div>

          <div v-show="!loading && validationGood && !validationBad" class="multiship-good-validation">
            <img :src="require('../../../../../img/checkmark.png')" alt="upload">
            <h3 class="title">
              {{ qty }} adressen succesvol toegevoegd!
            </h3>
            <p class="message">
              Ga door om af te rekenen. Of upload nogmaals <br> een .csv bestand met de knop hieronder
            </p>

            <button class="cart-btn button color-cta style-next size-large" @click.prevent="addToCart()"><span>{{ update ? 'Adressen bijwerken' : 'Plaats in winkelmand'}}</span></button>

            <a class="repeat-upload" href="" @click.prevent="$refs['input-file'].click()">Opnieuw uploaden</a>
          </div>
        </div>

        <div class="multiship-table" :class="validationBad ? 'full-width' : ''">
          <div v-show="validationBad" class="table">
            <b-table v-if="!errorMessage" :items="addresses" :fields="fields" responsive="sm"></b-table>
            <p style="margin-top: 2rem" v-else>{{ errorMessage }}</p>
          </div>
        </div>

        <input
          ref="input-file"
          type="file"
          accept=".csv"
          class="input-file"
          @change="uploadCsv"
          style="display:none"
        />
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="info-box">
        <a :href="downloadExampleUrl" class="title"
          >Voorbeeld .csv bestand</a
        >
        <p>
          Bestand moet een .csv zijn en gevraagde kolommen <br> bevatten. Download <a :href="downloadExampleUrl" target="_blank">hier</a> een voorbeeld.
        </p>
        <!-- Might be needed to download example with errors -->
        <!-- <a href="#" @click.prevent="downloadCsv">hier</a> -->
      </div>
      <div class="multiship-footer-button">
        <p>{{ errorMessage ? '1' : addresses.length }} fout(en) gevonden</p>
        <button
          class="button style-next size-small"
          @click.prevent="$refs['input-file'].click()"
          key="button"
        >
          Opnieuw uploaden
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import bModal from 'bootstrap-vue/es/components/modal/modal';
import bTable from 'bootstrap-vue/es/components/table/table'
import APIValidationCall from '../services/validation'
import APIUpdateAddressesCall from '../services/update-addresses'

export default {
  name: 'MultishipModal',
  components: {
    bModal,
    bTable
  },
  props: {
    itemId: {
        type: Number | null,
        required: false,
        default: null
    },
    passedQty: {
      type: Number | null,
      required: false,
      default: null
    },
    update: {
      type: Boolean | null,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      validationGood: false,
      validationBad: false,
      fields: [
        {
          key: 'index',
          label: '',
          formatter: value => {
            return value + 2
          },
          tdClass: 'indexColumn'
        },
        { key: 'Voornaam', label: 'Voornaam', tdClass: 'multiship-td-class', thClass: 'multiship-th-class' },
        { key: 'Tussenvoegsel', label: 'Tussenvoegsel', tdClass: 'multiship-td-class', thClass: 'multiship-th-class' },
        { key: 'Achternaam', label: 'Achternaam', tdClass: 'multiship-td-class', thClass: 'multiship-th-class' },
        { key: 'Straat', label: 'Straat', tdClass: 'multiship-td-class', thClass: 'multiship-th-class' },
        { key: 'Postcode', label: 'Postcode', tdClass: 'multiship-td-class', thClass: 'multiship-th-class' },
        { key: 'Plaatsnaam', label: 'Plaatsnaam', tdClass: 'multiship-td-class', thClass: 'multiship-th-class' },
        { key: 'error_message', label: '', tdClass: 'errorColumn', thClass: 'multiship-th-class' },
      ],
      addresses: [],
      allAddressesToDownload: [],
      errorMessage: null
    }
  },
  computed: {
    downloadExampleUrl () {
      let baseUrl = this.$store.state.baseUrl

      return baseUrl + 'multishipping/catalog/samplecsv'
    },
    qty () {
      return this.passedQty ? this.passedQty : this.$store.getters.qty
    }
  },
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-multiship')
    },
    makeItSingleAddress () {
      this.$store.commit('changeMultiship', false)
      this.$store.commit('saveAddresses', [])
      this.$store.commit('actionDisabled', false)
      this.loading = false
      this.validationBad = false
      this.validationGood = false
    },
    uploadCsv(e) {
      if (e.target.files.length < 1) return;
      const file = e.target.files[0],
        reader = new FileReader();

      if (!this.validateFileExt(e.target)) {
        alert('Geen geldig csv-bestand!')
        return;
      }

      this.validationGood = false
      this.validationBad = false
      this.errorMessage = null
      this.loading = true

      reader.onload = (e) => {
        let lines = e.target.result.split(/\r\n|\n/);
        let header = lines[0].split(/;/)
        let promise = new Promise((resolve) => {
          resolve(
            lines.map((line, index) => {
              if (line === '') return;
              if (index === 0) return

              let arr = line.split(/;/);

              return arr.map((l, i) => {
                let obj = {}
                obj[header[i]] = l.replace(/"/g, "");
                return obj
              })
          }).filter(l => l)
          )
        })

        promise.then((res) => {
          this.uploadForValidation(res)
        })
      };

      reader.readAsText(file);
      this.$refs['input-file'].value = '';
    },
    downloadCsv() {
      let header = Object.keys(this.allAddressesToDownload[0])
      let csv = '',
          el = document.createElement('a');
      csv = header.join(";") + '\n'
      this.allAddressesToDownload.forEach(a => {
        csv += Object.values(a).join(";") + '\n'
      })

      el.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      el.target = '_blank';
      el.download = 'addresses-sample.csv';
      el.click();
    },
    validateFileExt(oInput) {
    let _validFileExtensions = [".csv"];
    if (oInput.type == "file") {
      var sFileName = oInput.value;
        if (sFileName.length > 0) {
          var blnValid = false;
          for (var j = 0; j < _validFileExtensions.length; j++) {
              var sCurExtension = _validFileExtensions[j];
              if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                  blnValid = true;
                  break;
              }
          }

          if (!blnValid) {
              oInput.value = "";
              return false;
          }
        }
      }
    return true;
    },
    uploadForValidation (res) {
      let modifed_version = res.map(a => a.reduce((result, current) => { return Object.assign(result, current)}, {}))
      APIValidationCall(modifed_version, this.qty)
          .done((response) => {
              if (response[0].status === 'success') {
                this.$store.commit('saveAddresses', response[0].addresses)
                this.loading = false
                this.validationGood = true
              } else {
                if (response[0].file_check.length > 0) {
                  this.errorMessage = response[0].file_check
                  this.allAddressesToDownload = response[0].addresses
                } else {
                  this.addresses = response[0].addresses.filter(e => {if (e.error_message.length > 0) {return e}})
                  this.allAddressesToDownload = response[0].addresses
                }
                this.loading = false
                this.validationBad = true
              }
          })
          .fail((e) => {
            alert('Het uploaden is niet gelukt. Verwijder leestekens (zoals ?,!, ;) en speciale karakters (zoals ä, è, í, ô, ñ) uit je uploadbestand, sla het bestand op als CSV UTF-8 en upload het bestand daarna opnieuw.')
            this.makeItSingleAddress()
            this.hideModal()
            console.log(e)
          })
      setTimeout(() => {

      }, 2000);
    },
    addToCart () {
      if (this.update) {
        APIUpdateAddressesCall(this.$store.state.multiShipAdresses)
          .done(res => {
            this.hideModal()
          })
          .fail(e => {
            console.log(e)
            alert('Er is iets misgegaan')
          })
      } else {
        this.$store.commit('addToCartFromMultiship', true)
      }
    }
  }
}
</script>
