<template>
    <div>
        <modal-recipients :read-only="readOnly" :item-id="itemId" />
        <modal-message :item-id="itemId" />
        <modal-multiship :item-id="itemId" :passedQty="itemQty" :update="updateItem" />
    </div>
</template>

<script>
import ModalRecipients from "./components/modals/modal-recipients";
import ModalMessage from './components/modals/modal-message';
import ModalMultiship from './components/multiship/modals/multi-ship-modal';

export default {
    components: {
        ModalRecipients,
        ModalMessage,
        ModalMultiship
    },
    data() {
        return {
            itemId: null,
            readOnly: false,
            itemQty: null,
            updateItem: false
        }
    },
    methods: {
        disableEdit() {
            this.readOnly = true;
        },
        setRecipients(recipients) {
            this.$store.commit('resetRecipients');
            if (recipients === null) {
                return
            }
            recipients.map(recipient => this.$store.commit('addRecipient', recipient.email));
        },
        setItemId(itemId) {
            this.itemId = itemId
        },
        setMessage(message) {
            this.$store.commit('message', message);
        },
        showModal(id) {
            this.$root.$emit('bv::show::modal', id);
        },
        setItemQty(itemQty) {
            this.itemQty = itemQty
        },
        setUpdateItem(updateItem) {
            this.updateItem = updateItem
        }
    }
}
</script>

<style src='../../scss/styles.scss' lang="scss"/>
