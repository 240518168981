<template>
    <div class="message-step">
        <div class="email" :class="[ this.isValid ? 'valid' : this.email ? 'has-error' : '' ]">
            <label>E-mailadres</label>
            <input type="email"
                   name="recipient_email"
                   placeholder="Vul hier het e-mailadres in"
                   v-model="email"
                   @keyup.enter="submitEmail"
                   @blur="submitEmail"
            />
        </div>
        <div class="add-more-wrapper">
            <button class="button color-add-more"
                    :disabled="noOfEmails !== 0 && $store.state.qty >= noOfEmails"
                    @click.prevent="submitEmail">
                Toevoegen
            </button>
        </div>
    </div>
</template>

<script>
import emailRecipient from "../../../mixins/email-recipient";

export default {
    props: {
        noOfEmails: {
            type: Number,
            require: false,
            default: 0
        }
    },
    mixins: [emailRecipient],
    methods: {
        submitEmail() {
            if(!this.isValid) {
                return;
            }

            this.$emit('submit', this.email);
            this.email = null;
        },
    }
}
</script>
