import JQuery from 'jquery'
let $ = JQuery

const call = (addresses) => {
  return $.ajax({
    showLoader: true,
    url: '/zakelijk/multishipping/address/update',
    type: 'POST',
    data: {
      addresses: addresses
    },
    dataType: 'json'
  })
}

export default call
