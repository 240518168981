<template>
     <b-modal id="modal-add-recipients" dialog-class="modal-dialog step-recipients" size="lg" :hide-footer="readOnly" @shown="reset" no-close-on-backdrop>
        <template v-slot:modal-header>
            <h1 class="page-title">E-mailadres van de ontvanger(s)</h1>
            <div>
                <a v-if="!readOnly" class="text-lg text-muted" href="#" @click.prevent="resetRecipients">Verwijder alles</a>
                <button class="button-round" @click.prevent="hideModal"><i class="times"></i></button>
            </div>
        </template>
        <template v-slot:default>
            <add-recipient v-if="!readOnly" key="modal-add-recipient" @submit="submitEmail"/>

            <div class="gap list-of-recipients" :class="{ center: recipients.length < 1}">
                <div v-if="loadingEmails" class="text-center">
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div v-if="hasInvalidEmails && !loadingEmails" class="alert-recipients">
                    Niet alle adressen zijn correcte e-mailadressen
                </div>
                <div v-if="recipients.length < 1 && archive.length > 0 && !loadingEmails" class="no-recipients-wrapper">
                    <div @click="restoreRecipients">
                        <div class="circle">
                            <i class="fas fa-undo"></i>
                        </div>
                        <div>
                            <p>Herstel verwijderde e-mailadressen</p>
                        </div>
                    </div>
                </div>
                <div v-else-if="recipients.length < 1 && !loadingEmails" class="no-recipients-wrapper">
                    <div class="circle">
                        <i class="fas fa-info"></i>
                    </div>
                    <div>
                        <p>Geen e-mailadres toegevoegd</p>
                    </div>
                </div>
                <vuescroll v-else ref="vs" class="scrollable" :ops="ops">
                    <recipient
                        v-for="(recipient, index) in sortedRecipients"
                        :ref="`recipient-${index}`"
                        :key="`modal-${componentKey}-${index}`"
                        :index="index"
                        :recipient="recipient"
                        :read-only="readOnly"
                        @remove="removeRecipient"
                    />
                </vuescroll>
            </div>
        </template>

        <template v-slot:modal-footer>
            <input ref="input-file" type="file" accept="text/csv" class="input-file" @change="uploadCsv">
            <div class="info-box">
                <a href="#" class="title" @click.prevent="$refs['input-file'].click()">Upload .csv bestand</a>
                <p>Bestand moet een .csv zijn met een e-mailadres per regel. Download <a href="#" @click.prevent="downloadCsv">hier</a> een voorbeeld.</p>
            </div>
            <div>
                <transition-group name="slide">
                    <AppErrors key="errors" v-if="showErrors" @clicked="showErrors = false; errors = [];" :errorsToShow="errorsToShow"/>
                    <span key="address" class="text-bold">{{ recipients.length }} adressen</span>
                    <button
                        class="button style-next size-large"
                        :class="[ hasInvalidEmails ? 'disabled' : '']"
                        @click.prevent="saveRecipients"
                        key="button">
                        Adressen opslaan
                    </button>
                </transition-group>
            </div>
        </template>
    </b-modal>
</template>

<script>
import bModal from 'bootstrap-vue/es/components/modal/modal';
import list from "../../mixins/list";
import emailRecipient from "../../mixins/email-recipient";
import addRecipient from "../steps/step4/add-recipient";
import Recipient from "../steps/step4/recipient";
import AppErrors from '../steps/step4/errors.vue'
import vuescroll from "vuescroll";
import JQuery from 'jquery'

let $ = JQuery

export default {
    components: {
        'b-modal': bModal,
        vuescroll,
        addRecipient,
        Recipient,
        AppErrors
    },
    props: {
        readOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        itemId: {
            type: Number | null,
            required: false,
            default: null
        }
    },
    mixins: [list,emailRecipient],
    data() {
        return {
            errors: [],
            recipients: [],
            archive: [],
            ops: {
                bar: {
                    onlyShowBarOnScroll: false,
                    background: '#c1c1c1',
                    size: '5px',
                }
            },
            loadingEmails: false,
            showErrors: false,
            errorTimeout: 0
        }
    },
    mounted() {
        this.openModalOnLoad();
    },
    computed: {
        sortedRecipients() {
            return this.recipients
        },
        hasInvalidEmails() {
            return this.recipients.filter(({ email }) => !this.validEmail(email)).length > 0;
        },
        errorsToShow () {
            return this.errors.join('<br />')
        }
    },
    watch: {
        recipients: function () {
            this.forceRerender();
        }
    },
    methods: {
        reset() {
            this.recipients = [];
            this.recipients = this.recipients.concat(this.$store.state.recipients);
        },
        openModalOnLoad() {
            const urlParams = new URLSearchParams(window.location.search),
                displayRecipients = urlParams.get('displayRecipients');

            if (null !== displayRecipients) {
                let recipients
                const openModal = () => {
                    setTimeout(() => {
                        recipients = this.$store.state.recipients
                        if (recipients.length < 1) {
                            return openModal();
                        }
                        this.$root.$emit('bv::show::modal', 'modal-add-recipients')
                    }, 100)
                };

                openModal();
            }
        },
        submitEmail(email) {
            const recipient = {
                email: email
            }
            this.recipients.push(recipient);
        },
        uploadCsv(e) {
            if (e.target.files.length < 1) return;
            this.loadingEmails = true
            const file = e.target.files[0],
                reader = new FileReader();

            this.recipients = [];
            this.removeRecipients();

            reader.onload = (e) => {
                let lines = e.target.result.split(/\r\n|\n/);
                let promise = new Promise((resolve) => {
                    resolve(
                        lines.map((line) => {
                            if (line === '') return;
                            let recipient = line.split(/,/);
                            if (!recipient[0]) return;
                            this.addRecipient(recipient[0]);
                    }))
                })

                promise.then(() => {
                    this.loadingEmails = false
                })
            };

            reader.readAsText(file);
            this.$refs['input-file'].value = '';
        },
        downloadCsv() {
            let csv = '',
                el = document.createElement('a');
            csv += 'janjansen@voorbeeld.nl\n';
            csv += 'annedejong@voorbeeld.nl\n';

            el.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
            el.target = '_blank';
            el.download = 'cadeaukaart_emailadressen.csv';
            el.click();
        },
        addRecipient(email) {
            email = email.replace(/^"(.*)"$/, '$1');
            email = email.replace(/[^\x00-\x7F]/g, "");
            email = email.trim();
            this.recipients.push({
                email: email
            });
        },
        removeRecipient(index) {
            this.recipients.splice(index, 1);
        },
        saveRecipients() {
            if (this.validateRecipients()) {
                if (this.itemId) {
                    this.updateCart()
                } else {
                    this.removeRecipients();
                    this.recipients.map((recipient) => this.$store.commit('addRecipient', recipient.email));
                    this.hideModal();
                }
            } else {
                this.errorTimeout += 2000
                this.showErrors = true
                setTimeout(() => {
                    this.showErrors = false
                    this.errors = [];
                }, this.errorTimeout);
            }
        },
        resetRecipients() {
            this.archive = [...this.recipients];
            this.recipients = [];
            this.removeRecipients();
        },
        restoreRecipients() {
            this.loadingEmails = true

            setTimeout(() => {
                let promise = new Promise((resolve) => {
                        this.recipients = [...this.archive];
                        this.archive = [];
                        resolve(
                            this.recipients.map((recipient) => this.$store.commit('addRecipient', recipient.email))
                        )
                    })

                promise.then(() => {
                    this.loadingEmails = false
                })
            }, 300);


        },
        removeRecipients() {
            this.$store.commit('resetRecipients');
        },
        hideModal() {
            this.$root.$emit('bv::hide::modal', 'modal-add-recipients')
        },

        updateCart() {
            this.$store.qty = this.recipients.length
            let url = ''
            if (this.$store.state.storeCode === 'zakelijk') {
                url = '/zakelijk/checkout/messagerecipients/post'
            } else {
                url = '/checkout/messagerecipients/post'
            }

            if (this.validateRecipients()) {
                this.removeRecipients();
                this.recipients.map((recipient) => this.$store.commit('addRecipient', recipient.email));
                $.ajax({
                    showLoader: true,
                    url: url,
                    type: 'POST',
                    data: {
                        item_id: this.itemId,
                        recipients: this.recipients
                    },
                    dataType: 'json'
                }).done(function(data) {
                    location.reload();
                })
            } else {
                this.errorTimeout += 2000
                this.showErrors = true
                setTimeout(() => {
                    this.showErrors = false
                    this.errors = [];
                }, this.errorTimeout);
            }
        },
    }
}
</script>
