import 'idempotent-babel-polyfill'
import Vue from 'vue'
import store from './store'
import popups from './modals.vue'

var VuePopups = new Vue({
    el: '#modals',
    store,
    render: h => h(popups)
});

window.VuePopups = VuePopups;
